import React from "react";

const AppVersion = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", fontSize: "9px", opacity: 0.3, paddingBottom: 4 }}>
      v2.1.250
    </div>
  );
};

export default AppVersion;
